import React from 'react'
import Layout from 'src/components/Layout'
import SEO from 'src/components/seo'
import { graphql } from 'gatsby'

import 'src/styles/pga.scss'
import 'src/styles/speaker.scss'

import SpeakerList from 'src/components/SpeakerList'

const SpeakerPage = ({ data }) => {
  const speaker_content = data.allRestApiApiSpeakers.edges[0].node.content
  const localeClass = data.allRestApiApiSpeakers.edges[0].node.locale
  return (
    <Layout locale={'vn'} hasContactSection={false}>
      <SEO title="Speakers" />
      <div
        className="speakers-container"
        style={{
          backgroundImage: `url(${speaker_content.speaker_background})`
        }}
      >
        <SpeakerList
          listTitle={speaker_content.speakers_title}
          data={speaker_content.speakers}
          labels={{ topic: speaker_content.topic }}
          locale={localeClass}
        />
        <SpeakerList
          listTitle={speaker_content.prudential_speakers_title}
          data={speaker_content.prudential_speakers}
          labels={{ topic: speaker_content.topic }}
          locale={localeClass}
        />
      </div>
    </Layout>
  )
}

export default SpeakerPage
export const GatsbyQuery = graphql`
  {
    allRestApiApiSpeakers(filter: { locale: { eq: "vn" } }) {
      edges {
        node {
          content {
            country
            topic
            speakers_title
            prudential_speakers_title
            speaker_background
            speakers {
              id
              avatar
              name
              job_title
              topic
              description
              mdrt_tot_cot
            }
            prudential_speakers {
              id
              avatar
              name
              job_title
              topic
              description
              mdrt_tot_cot
            }
          }
          locale
        }
      }
    }
  }
`
